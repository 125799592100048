
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "../../store/enums/StoreEnums";
  import { getIllustrationsPath } from "@/core/helpers/assets";
  import { getMerchantRequest } from "@/latipay/apis/services/MerchantsService";
  import { reactive } from "vue";
  import { unref, ref } from "vue";
  import { getWalletsRequest } from "@/latipay/apis/services/WalletsService";
  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import { DateTimeUtils } from "@/latipay/utils/DateTimeUtils";
  import { ElMessage } from "element-plus";
  import useClipboard from "vue-clipboard3";
  import { ArrowDown } from "@element-plus/icons-vue";
  import { ElMessageBox } from "element-plus";
  import {
    getWalletStatementsRequest,
    postWalletStatementRequest
  } from "@/latipay/apis/services/WalletStatementsService";
  import { PostWalletParameter } from "@/latipay/apis/models/walletStatement/WalletStatementsResponse";
  import { WalletsResponse } from "@/latipay/apis/models/wallet/WalletsResponse";
  import _ from "lodash";
  import { EnumsConstant } from "@/store/enums/constants";


  export class AdjustWallet {
    availableBalance = 0;
    createdAt = "";
    currency = "";
    enabled = true;
    id = 0;
    merchantId = 0;
    name = "";
    slug = "";
    unavailableBalance = 0;
    updatedAt = "";

  }


  class GroupWallet {
    currency = "";
    availableBalance = 0;
    unavailableBalance = 0;
  }

  export default defineComponent({
    name: "merchantDetail",
    components: {},
    props: {
      id: Number
    },
    setup(props) {
      const store = useStore();
      const id = props.id ? props.id : 0;
      //
      const pageNumber = ref(1);
      const pageSize = ref(20);
      const pageBalanceNumber = ref(1);
      const pageBalanceSize = ref(20);
      const sort = ref<string | null>(null);
      const currencies = EnumsConstant.CurrencyCode;
      const fiatCurrencyCode = EnumsConstant.FiatCurrencyCode;
      const loading = ref(false);

      const isBalanceActive = ref(true);
      const isRiskActive = ref(false);
      const isSettingActive = ref(false);
      const isProfileActive = ref(false);
      const isWalletActive = ref(false);

      const statementDrawer = ref(false);
      const adjustmentDrawer = ref(false);

      const currencyTab = ref('NZD');
      const showStatementWallet = ref(1);
      let groupedFiatWallets: GroupWallet[] = [];
      let groupedShowFiatWallets: GroupWallet[] = [];
      let groupedCryptoWallets: GroupWallet[] = [];
      const { toClipboard } = useClipboard();
      const { errorRef, merchantRef, getMerchant } = getMerchantRequest();

      const adjustFormRef = ref();
      const loadingRef = ref(false);
      let selectWallet = new AdjustWallet();

      const adjustForm = reactive({
        adjustType: "",
        balanceType: "",
        amount: "",
        reason: ""
      });

      const successAlert = (message => {
        ElMessage({
          showClose: true,
          message: message,
          type: "success"
        });
      });

      const failAlert = (message => {

        ElMessage.error(message);
      });


      const fetchMerchant = async (id: number) => {
        loadingRef.value = true;
        await getMerchant(id);
        loadingRef.value = false;
      };

      const {
        walletsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getWallets
      } = getWalletsRequest();

      const {
        walletsRef : balanceWalletsRef,
        totalRef : balanceTotalRef,
        pageNumberRef: balancePageNumberRef,
        pageSizeRef : balancePageSizeRef,
        getWallets : getBalanceWallets
      } = getWalletsRequest();


      const getData = async () => {
        loadingRef.value = true;

        // await updateQueries();
        await getWallets(
          id,
          null,
          sort.value,
          pageNumber.value,
          pageSize.value
        );
        reinitializeComponents();

        loadingRef.value = false;

        console.log(walletsRef.value);


        // groupedWallets = _.groupBy(walletsRef.value, wallet => wallet.currency);

        reinitializeComponents();

        await showFiatCrypto();
        // groupedFiatWallets = (Object.entries(groupedWallets) as unknown as Array<String>).filter(it => currencies.includes(it[0]));
        console.log(groupedFiatWallets);
      };

      const getBalanceData = async () => {
        loadingRef.value = true;

        // await updateQueries();
        await getBalanceWallets(
          id,
          currencyTab.value,
          sort.value,
          pageBalanceNumber.value,
          pageBalanceSize.value
        );
        reinitializeComponents();

        loadingRef.value = false;

        console.log(balanceWalletsRef.value);



      };

      const updatePageSize = async (val: number) => {
        console.log(`${val} items per page`);
        pageSize.value = val;
        await getData();
      };
      const updatePageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        pageNumber.value = val;
        await getData();
      };
      const updateWalletStatementPageSize = async (val: number) => {
        console.log(`${val} items per page`);
        walletStatementPageSizeRef.value = val;
        await getTransactionsData(showStatementWallet.value);
      };
      const updateWalletStatementPageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        walletStatementPageNumberRef.value = val;
        await getTransactionsData(showStatementWallet.value);
      };

      const updateBalanceWalletPageSize = async (val: number) => {
        console.log(`${val} items per page`);
        balancePageSizeRef.value = val;
        await getBalanceData();
      };
      const updateBalanceWalletPageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        balancePageNumberRef.value = val;
        await getBalanceData();
      };

      const showCurrencyWallet = async (currency: string) => {
        console.log(currency);
        currencyTab.value = currency;

        await getBalanceData();
      };


      const chooseProfile = async () => {
        isProfileActive.value = true;
        isWalletActive.value = false;
        isBalanceActive.value = false;
        isRiskActive.value = false;
        isSettingActive.value = false;
        reinitializeComponents();

      };
      const chooseWallet = async () => {
        isProfileActive.value = false;
        isWalletActive.value = true;
        isBalanceActive.value = false;
        isRiskActive.value = false;
        isSettingActive.value = false;
        reinitializeComponents();
      };
      const chooseBalance = async () => {
        isProfileActive.value = false;
        isWalletActive.value = false;
        isBalanceActive.value = true;
        isRiskActive.value = false;
        isSettingActive.value = false;
        reinitializeComponents();

      };
      const chooseRisk = async () => {
        isProfileActive.value = false;
        isWalletActive.value = false;
        isBalanceActive.value = false;
        isRiskActive.value = true;
        isSettingActive.value = false;
        reinitializeComponents();

      };
      const chooseSetting = async () => {
        isProfileActive.value = false;
        isWalletActive.value = false;
        isBalanceActive.value = false;
        isRiskActive.value = false;
        isSettingActive.value = true;
        reinitializeComponents();

      };


      onMounted(async () => {
        // setCurrentPageTitle("Merchant " + props.id);
        await fetchMerchant(id);
        await getData();
        await getBalanceData();


      });

      const showFiatCrypto = async () => {

        loadingRef.value = true;
        let groupedWallets = _.mapValues(_.groupBy(walletsRef.value, "currency"),
          clist => clist.map(car => _.omit(car, "currency")));
        console.log(Object.entries(groupedWallets));

        if (Object.entries(groupedWallets) !== []) {
          for (let i in Object.entries(groupedWallets)) {


            if (fiatCurrencyCode.map(it => it.code).includes(Object.entries(groupedWallets)[i][0])) {
              console.log(Object.entries(groupedWallets)[i][0]);

              let newFiatWallet = new GroupWallet();
              newFiatWallet.currency = Object.entries(groupedWallets)[i][0];
              newFiatWallet.availableBalance = Object.entries(groupedWallets)[i][1].map(it => it.availableBalance).reduce((partialSum, a) => partialSum + a, 0);
              newFiatWallet.unavailableBalance = Object.entries(groupedWallets)[i][1].map(it => it.unavailableBalance).reduce((partialSum, a) => partialSum + a, 0);
              console.log(newFiatWallet);
              groupedFiatWallets.push(newFiatWallet);


            } else {

              let newCryptoWallet = new GroupWallet();
              newCryptoWallet.currency = Object.entries(groupedWallets)[i][0];
              newCryptoWallet.availableBalance = Object.entries(groupedWallets)[i][1].map(it => it.availableBalance).reduce((partialSum, a) => partialSum + a, 0);
              newCryptoWallet.unavailableBalance = Object.entries(groupedWallets)[i][1].map(it => it.unavailableBalance).reduce((partialSum, a) => partialSum + a, 0);
              console.log(newCryptoWallet);
              groupedCryptoWallets.push(newCryptoWallet);
            }
          }
        }
        reinitializeComponents();
        loadingRef.value = false;
        groupedShowFiatWallets = groupedFiatWallets;
      }

      const copySlug = async (slug: string) => {
        try {
          await toClipboard(slug);
          ElMessage({
            showClose: true,
            message: "Copy successfully!",
            type: "success"
          });
        } catch (e) {
          console.error(e);
        }
      };

      const showStatement = async (val: number) => {

        statementDrawer.value = true;
        showStatementWallet.value = val;
        await getTransactionsData(val);
      };

      const showAdjustment = async (wallet: WalletsResponse) => {

        console.log(wallet);

        selectWallet.availableBalance = wallet.availableBalance;
        selectWallet.currency = wallet.currency;
        selectWallet.unavailableBalance = wallet.unavailableBalance;
        selectWallet.enabled = wallet.enabled;
        selectWallet.id = wallet.id;
        selectWallet.merchantId = wallet.merchantId;
        selectWallet.name = wallet.name;
        adjustmentDrawer.value = true;

      };


      const handleClose = (done: () => void) => {
        ElMessageBox.confirm("Are you sure you want to close this?")
          .then(() => {
            done();
          })
          .catch(() => {
            // catch error
          });
      };

      const calculateSum = async (i) => {

        console.log("343");
        console.log(i);
        if (i) {
          // for () ;

          console.log("343");

        }
      };

      const {
        walletStatementsRef,
        totalRef: walletStatementTotalRef,
        pageNumberRef: walletStatementPageNumberRef,
        pageSizeRef: walletStatementPageSizeRef,
        getWalletStatements
      } = getWalletStatementsRequest();

      const getTransactionsData = async (val) => {
        loadingRef.value = true;

        // await updateQueries();
        await getWalletStatements(
          null,
          val,
          null,
          null,

          sort.value,
          walletStatementPageNumberRef.value,
          walletStatementPageSizeRef.value
        );
        reinitializeComponents();

        loadingRef.value = false;

        console.log(walletStatementsRef);
      };


      const confirmAdjust = async () => {

        console.log("123213");
        const form = unref(adjustFormRef);
        if (!form) {
          return;
        }
        try {
          console.log("4546");
          await form.validate();
          if (+adjustForm.amount <= 0) {
            failAlert("Input amount must be larger than 0.");

          } else {

            let newAdjust = new PostWalletParameter();
            newAdjust.balanceType = adjustForm.balanceType;
            newAdjust.merchantId = +selectWallet.merchantId;
            newAdjust.walletId = +selectWallet.id;
            if (adjustForm.adjustType === "increase") {
              newAdjust.amount = +adjustForm.amount;
            } else if (adjustForm.adjustType === "decrease") {
              newAdjust.amount = +adjustForm.amount * -1;
            }
            newAdjust.remarks = adjustForm.reason;


            await postAdjustData(newAdjust);
          }
        } catch (err) {
          console.log(err);
        }

      };
      const { postErrorRef, postWalletStatement } = postWalletStatementRequest();
      const postAdjustData = async (data: PostWalletParameter) => {
        loadingRef.value = true;
        await postWalletStatement(data);

        if (!postErrorRef.value) {
          loadingRef.value = false;

          successAlert("Adjust wallet balance successfully!");
          adjustForm.adjustType = "";
          adjustForm.amount = "";
          adjustForm.balanceType = "";
          adjustForm.reason = "";
          selectWallet = new AdjustWallet();
          adjustmentDrawer.value = false;
          await getData();

        } else {
          failAlert("Something wrong happened! Please have a check.");
          loadingRef.value = false;
        }


      };


      return {
        getIllustrationsPath,
        loadingRef,
        merchantRef,
        errorRef,
        getData,
        walletsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        balancePageNumberRef,
        balancePageSizeRef,
        balanceTotalRef,
        DateTimeUtils,
        updatePageSize,
        updatePageNumber,
        isProfileActive,
        isWalletActive,
        isBalanceActive,
        isRiskActive,
        isSettingActive,
        chooseProfile,
        chooseWallet,
        copySlug,
        handleClose,
        statementDrawer,
        showStatement,
        walletStatementTotalRef,
        walletStatementPageNumberRef,
        walletStatementPageSizeRef,
        updateWalletStatementPageSize,
        updateWalletStatementPageNumber,
        walletStatementsRef,
        adjustmentDrawer,
        adjustForm,
        confirmAdjust,
        showAdjustment,
        selectWallet,
        adjustFormRef,
        chooseBalance,
        chooseRisk,
        chooseSetting,
        groupedFiatWallets,
        groupedCryptoWallets,
        currencies,
        fiatCurrencyCode,
        groupedShowFiatWallets,
        showFiatCrypto,
        currencyTab,
        showCurrencyWallet,
        updateBalanceWalletPageSize,
        updateBalanceWalletPageNumber,
        showStatementWallet,
        balanceWalletsRef

      };

    },
    data() {
      return {};
    }

  });
